import React from 'react';
import { Container, Row, Col, Jumbotron, Image, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import OwnerTypeSection from '../components/home/OwnerTypeSection';

import { withTranslation, useTranslation } from 'react-i18next';
import CommunityModal from '../components/CommunityModal';

const Styled = styled.div`
    .home-header {
        background-color: #FFFFFF;
        padding-bottom: 0;
        padding-top: 75px;
        margin-top: -75px;
        height: 100vh;
        display:flex;
        align-items:center;

        .card {
            border-radius: 40px;
            overflow: hidden;
            border: none;
        }

        .btn-black {
            max-width:200px;
            box-shadow: none;
            border-radius: 5px;
            &:hover{
                @media (min-width: 768px) {
                    color: #fff !important;
                    background: #000 !important;
                    border: 1px solid #000 !important;
                }
            }
        }
    }

    .home-cta {
        text-align: center;
        padding-bottom: 124px;
        
        .p-md-5 {
            padding-bottom: 0 !important;
        }

        img {
            max-width:120px;
            max-height:120px;
        }
        
        h3 {
            margin: 30px 0;
        }

        h3.margin-text {
            margin-bottom: 57px;
        }

        p {
            color: #605B5B;
            font-size: 16px;
            line-height:1.3em;
        }

        h3.en-lang {
            height: 51px;
        }

        h3.es-lang {
            height: 82.2px;
        }
    }

    .home-integration {
        text-align: center;
        

        img {
            max-width:210px;
            max-height:50px;
        }
        
        h5 {
            margin: 30px 0;
        }

        p {
            color: #605B5B;
            font-size: 13px;
            line-height:1.3em;
        }

    }

    .home-features section:nth-child(2) {
        padding-top: 0;
    }

    .integration {
        margin-bottom: 20px
    }

    .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
    }

    .img-max {
        width: auto !important;
        max-width: 210px;
        max-height: 50px;
    }

    .accordion {
        .card {
            border: 0;

            .card-header {
                font-weight: 700;
                font-size: 20px;
                color: #000;
                border-bottom: 2px solid #000;
                background: none;
                padding: 1em 0;
                margin-bottom:0;
                cursor: pointer;

                .MuiSvgIcon-root {
                    font-size: 20px;
                }
            }

            .card-body {
                color: #605B5B 
            }

            &:last-child .card-header {
                margin-bottom:-2px
            }
        }
    }

    .home-section {
        background-color: #f6f6f4;
        text-align: center;
        padding: 150px 0;
        margin: 0
    }

    .coming-label {
        max-width: 210px;
        display: inline-block;
        padding: 10px 20px;
        background: #94749D;
        color: #fff;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .home-get-started {
        background: #C67D5A;
        text-align:center;
        padding: 150px 0;
        margin-bottom: -2px;
        position: relative;

        .btn-primary {
            min-width:250px;
        }

        p {
            font-size:23px;
        }
    }

    .outline-btn {
        box-shadow: none;
        border: 0.5px solid;
    }

    .arrow-icon{
        transition: transform 0.1s ease-in 0s;
    }
    
    .arrow-icon.show{
        transform: rotate(90deg)
    }

    @media (max-width: 1024px) {
        .home-header {
            display: block;
            height: 100%;
            padding: 108px 0 12.5rem 0;
        }
    }

    @media (max-width: 425px) {
        .home-header {
            padding-top: 120px;
            padding-bottom: 50px;

            .card {
                margin-bottom: 30px;
            }

            .flex-column {
                align-items:center;
            }
        }

        .home-cta {
            h3, h3.margin-text {
                margin: 10px;
                height: unset !important;
            }
            p{
                margin-bottom: 50px;
            }
        }

        .home-section {
            padding: 50px 0;
        }

        .home-integration h3 {
            margin: 10px 0;
        }

        .home-integration p {
            margin-bottom: 50px;
        }

        .layout-padding .col-md-5 {
            text-align:center;
        }

        .layout-margin .btn-secondary {
            margin-bottom: 50px;
        }
    }
    .btn-white {
        @media (min-width: 768px) {
            border-radius: 5px;
        }

        &:hover{
            @media (min-width: 768px) {
                color: #000 !important;
                border: 1px solid #FFFFFF !important;
                background: #fff !important;
            }
        }
    }
`;

const HomePage = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { i18n } = useTranslation();
    const { t } = props;

    const integrations = ["Stripe", "Siteminder", "myAllocator", "Hostelworld", "Goki", 
            "Booking.com", "Airbnb", "Expedia", "WuBook", "Mailchimp", "Zapier", "QuickBooks"]
    
    const getCtaHeadingClass = () =>{
        switch(i18n.language){
            case 'es':
                return 'es-lang';
            default:
                return 'en-lang'
        }
    }
    return(
        <React.Fragment>
            <Styled>
                <Jumbotron fluid className="home-header">
                    <Container>
                        <Row className="mb-5">
                            <Col md={6}>
                                <Card>
                                    <Image src={process.env.PUBLIC_URL + '/assets/images/home-header2.jpg'} fluid />
                                </Card>
                            </Col>
                            <Col md={{span:5, offset:1}} className="d-flex flex-column justify-content-center">
                                <h1 className="heading">{t('home.headerTitle')}</h1>
                                <p className="sub-heading">{t('home.headerSubHeading')}</p>
                                <a href="https://workspace.counter.app/signup" className="btn btn-black">{t('home.headerBtn')}</a>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                

                <Container>
                    <section className="layout-margin">
                        <Row>
                            <Col md={{span:5, offset:4}}>
                                <h2 className="heading text-center">{t('home.ctaTitle')}</h2>
                            </Col>
                        </Row>
                        <Row className="home-cta">
                            <Col md={4} className="p-md-5">
                                <Image src={process.env.PUBLIC_URL + '/assets/images/zero-training.png'} />
                                <h3 className={getCtaHeadingClass()}>{t('home.ctaHeading1')}</h3>
                                <p>{t('home.ctaContent1')}</p>
                            </Col>
                            <Col md={4} className="p-md-5">
                                <Image src={process.env.PUBLIC_URL + '/assets/images/one-place.png'} />
                                <h3 className={getCtaHeadingClass()}>{t('home.ctaHeading2')}</h3>
                                <p>{t('home.ctaContent2')}</p>
                            </Col>
                            <Col md={4} className="p-md-5">
                                <Image src={process.env.PUBLIC_URL + '/assets/images/fair-pricing.png'} />
                                <h3 className={getCtaHeadingClass()}>{t('home.ctaHeading3')}</h3>
                                <p>{t('home.ctaContent3')}</p>
                            </Col>
                        </Row>
                        <Row className="layout-margin">
                            <OwnerTypeSection />
                        </Row>
                    </section>
                </Container>

                <Jumbotron fluid className="home-section layout-margin" id="integrations">
                    <Container>
                        <Row className="mb-5">
                            <Col md={{span:6, offset:3}}>
                                <h2 className="heading mb-5">
                                    {t('home.section4Heading')}<br />
                                    {t('home.section4Heading2')}
                                </h2>
                                <p className="grey-text">{t('home.section4SubHeading')}</p>
                            </Col>
                        </Row>
                        <Row className="home-integration">
                            {integrations.map((item, index) =>
                                <Col md={3} key={index} className="integration">
                                    <div className="img-container">
                                        <Image src={process.env.PUBLIC_URL + '/assets/images/' + item + '-icon.png'} className="img-max"/>
                                    </div>
                                    <h5>{item}</h5>
                                    <p className="desc">{t(`home.section4CtaContent${index}`)}</p>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Jumbotron>

                <Container className="home-features">
                    <section className="layout-margin">
                         <Row>
                            <Col md={5} className="d-flex flex-column justify-content-center">
                                <h2 className="heading">{t('home.section5Heading')}</h2>
                                <p className="grey-text">{t('home.section5SubHeading')}</p>
                            </Col>
                            <Col md={{span:6, offset:1}} className="text-right">
                                <Image src={process.env.PUBLIC_URL + '/assets/images/bed-allocation.jpg'}width="80%" />
                            </Col>
                        </Row>
                    </section>
                    <section className="layout-padding">
                         <Row className="row-reverse">
                            <Col md={5}>
                                <Image src={process.env.PUBLIC_URL + '/assets/images/automated-pricing.png'} width="80%" />
                            </Col>
                            <Col md={{span:6, offset:1}} className="d-flex flex-column justify-content-center align-items-end text-right">
                                <h2 className="heading">{t('home.section6Heading')}</h2>
                                <p className="grey-text">{t('home.section6SubHeading')}</p>
                            </Col>
                        </Row>
                    </section>
                    <section className="layout-margin">
                         <Row>
                            <Col md={6} className="d-flex flex-column align-items-start justify-content-center">
                                <h2 className="heading">{t('home.section7Heading')}</h2>
                                <p className="grey-text">{t('home.section7SubHeading')}</p>
                            </Col>
                            <Col md={{span:5, offset:1}}>
                                <Image src={process.env.PUBLIC_URL + '/assets/images/illustration-experience2.png'}fluid />
                            </Col>
                        </Row>
                    </section>
                </Container>  

                <Jumbotron fluid className="home-get-started">
                    <Container>
                        <Row>
                            <Col md={{span:6, offset:3}}>
                                <h2 className="heading mb-3">
                                    {t('home.section8Heading')}
                                </h2>
                                <p className="mb-3">{t('home.section8SubHeading')}</p>
                                <Button className="btn-white" variant="primary" href="https://workspace.counter.app/signup">{t('home.section8Btn')}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>              
            </Styled>
            <CommunityModal  
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
        )
    }

export default withTranslation()(HomePage);