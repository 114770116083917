import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "./App.css";
import { Spinner } from "react-bootstrap";

import HomePage from "./pages/Home";
import { Pricing } from "./pages/Pricing";
// import FAQ  from './pages/FAQ';
import Community from "./pages/Community";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Accessibility } from "./pages/Accessibility";
import { Terms } from "./pages/Terms";
import { NoMatch } from "./pages/NoMatch";

import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense
          fallback={
            <div className="spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          }
        >
          <NavigationBar />
          <Router>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/pricing" component={Pricing} />
              <Route
                path="/about"
                render={() => {
                  window.location.replace(
                    "https://www.hostelworldgroup.com/about-us"
                  );
                  return null;
                }}
              />
              {/* <Route path="/faq" component={ FAQ }/> */}
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms" component={Terms} />
              <Route path="/accessibility" component={Accessibility} />
              <Route path="/community" component={Community} />
              <Route component={NoMatch} />
            </Switch>
            <Link to="/#integrations" key="integrations" />
          </Router>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App;
