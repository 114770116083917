import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import CommunityModal from './CommunityModal';

const Styled = styled.div`
    .navbar-light .navbar-nav {
        .nav-link {
            color: #000;
            font-size: 14px;
            margin: 0 15px;
            padding: .618em 0;
        }

        .nav-link:hover {
            color: rgba(0, 0, 0, 0.89);
        }

        .nav-item:last-child .nav-link {
            margin-right:0;
        }
    }
    
    .logo img {
        max-width: 50px;
    }

    .login {
        margin-left: 50px !important;
        
        @media (max-width: 987px) {
            margin-left: 15px !important;
        }
    }

    .signup-btn{
        @media (min-width: 987px) {
            background: #000;
            color: #fff !important;
            border: 2px solid #000 !important;
            padding: 6px 20px !important;
            border-radius: 5px;
        }

        &:hover{
            @media (min-width: 987px) {
                color: #fff !important;
                background: #000;
                border: 2px solid #000 !important;
            }
        }
    }

    .nav-root-container{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
    }

    .nav-item a{
        font-weight: bold;
    }

    .nav-toggle-btn:focus{
        outline: none;
    }

    // scroll effect..................

    .header-container {
        transition: opacity 0.2s ease-in-out;
    }

    .nav-root-container:hover .nav-hide{
        background: #fff !important;
        border-bottom: .0625rem solid #dbd9d2 !important;
        opacity: 1 !important;

        .header-container{
            opacity: 1;
        }
    }

    .nav-root-container .navbar.is-scolling{
        background: #fff;
    }

    .nav-hide.pricing{
        background: #F0F0F0 !important;
    }

    .nav-root-container .pricing {
        background: #F0F0F0 !important;
    }
`;
class NavigationBar extends Component{
    state ={
        modalShow: false,
        scrollDirection: null,
        scrollY: window.scrollY,
        navMenuOpen: false
    }

    constructor(props){
        super(props);
        this.prevScroll = 0;
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleNavbarScroll);
        console.log(window.location.pathname);
    }

    handleNavbarScroll = () =>{
        const { scrollDirection } = this.state;
        const newScroll = window.scrollY;

        if(this.prevScroll === newScroll) return;

        if (scrollDirection !== 'up' && (this.prevScroll > newScroll)) {
            this.setState({scrollDirection: 'up'});
            
        } else if (scrollDirection !== 'down' && (this.prevScroll < newScroll)) {
            this.setState({scrollDirection: 'down'});
        }
        
        this.prevScroll = newScroll;
        this.setState({ scrollY: newScroll });
    }

    getNavBarClass = () =>{
        const { navMenuOpen, scrollY } = this.state;
        if(navMenuOpen){
            return 'is-scolling';
        }

        if(scrollY > 1){
            return 'is-scolling';
        }
        return '';
    } 

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleNavbarScroll);
    }

    render(){
        const { modalShow, navMenuOpen, scrollDirection } = this.state;
        const { t } = this.props;
        
        return(
            <Styled>
            <div className={`nav-root-container`}>
                <Navbar expand="lg" className={`${this.getNavBarClass()} ${scrollDirection === 'down' ? 'nav-hide' : ''} ${window.location.pathname==='/pricing' ? 'pricing' : null}`} >
                    <Container fluid>
                        <Navbar.Brand href="/" className="logo header-container"> 
                            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Counter" title="Counter"/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="nav-bar" className="nav-toggle-btn" onClick={()=>this.setState({navMenuOpen: !navMenuOpen})} />
                        <Navbar.Collapse id="#nav-bar">
                            <Nav className={`ml-auto header-container`}>
                                <Nav.Item>
                                    <Nav.Link href="/pricing">
                                        {t('nav.nav2')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="https://www.hostelworldgroup.com/about-us">
                                        {t('nav.nav3')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="login" href="https://workspace.counter.app/login">
                                        {t('nav.navBtn1')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="signup-btn" href="https://workspace.counter.app/signup">
                                        {t('nav.navBtn2')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <CommunityModal  
                show={modalShow}
                onHide={() => this.setState({modalShow : false})}
            />
            </Styled>
        )
    }
}

export default withTranslation()(NavigationBar);