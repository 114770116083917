import React, { useState } from 'react';
import { Col, Image, Card, Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const businessTypeImages = {
    newBusiness: process.env.PUBLIC_URL + '/assets/images/new_business.png',
    smallToMedium: process.env.PUBLIC_URL + '/assets/images/small_to_medium.png',
    chains: process.env.PUBLIC_URL + '/assets/images/chains.png'
};

const OwnerTypeSection = props =>{
    const [selectedAccImgUrl, setSelectedAccImgUrl] = useState(businessTypeImages.newBusiness);
    const [activeKey, setActiveKey] = useState(0);

    const { t } = props;

    const handleAccordianChange = (type) =>{
        const prev = activeKey;
        if(prev === type){
            setActiveKey(3);
            return;
        }
        switch(type){
            case 0:
                setSelectedAccImgUrl(businessTypeImages.newBusiness);
                setActiveKey(0);
                break;
            
            case 1:
                setSelectedAccImgUrl(businessTypeImages.smallToMedium);
                setActiveKey(1);
                break;
            
            case 2:
                setSelectedAccImgUrl(businessTypeImages.chains);
                setActiveKey(2);
                break;

            default:
                setSelectedAccImgUrl(businessTypeImages.newBusiness);
                setActiveKey(0);
                break;
        }
    }

    return(
        <>
            <Col md={5}>
                <h2 className="heading">{t('home.section3Heading')}</h2>
                <Accordion defaultActiveKey="0" >
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={e=>handleAccordianChange(0)}>
                            <ChevronRightIcon className={`arrow-icon ${activeKey === 0 ? 'show' : ''}`}/> {t('home.section3SubHeading1')}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>{t('home.section3Content1')}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={e=>handleAccordianChange(1)}>
                            <ChevronRightIcon className={`arrow-icon ${activeKey === 1 ? 'show' : ''}`} /> {t('home.section3SubHeading2')}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>{t('home.section3Content2')}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={e=>handleAccordianChange(2)}>
                            <ChevronRightIcon className={`arrow-icon ${activeKey === 2 ? 'show' : ''}`} /> {t('home.section3SubHeading3')}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>{t('home.section3Content3')}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
            <Col md={{span:6, offset:1}} className="d-flex flex-column justify-content-center align-items-center">
                <Image width="300px" src={selectedAccImgUrl} fluid />
            </Col>
        </>
    )
}

export default withTranslation()(OwnerTypeSection);