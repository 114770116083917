import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const COUNTER_ADDRESS = 'Floor 3, Charlemont Exchange,Charlemont St, Dublin,D02 VN88';

export const PrivacyPolicy = () => (
    <Container>
        <Row className="mb-5">
            <Col role="main">
                <Row className="mb-5">
                    <Col xs={12}>
                        {/* <Nav>
                            <NavDropdown title="Privacy policy" className="sidebar-responsive d-lg-none d-md-block">
                                <NavDropdown.Item  href="/privacy-policy">Privacy policy</NavDropdown.Item>
                                <NavDropdown.Item href="/terms">Terms & conditions</NavDropdown.Item>
                                <NavDropdown.Item href="/accessibility">Accessibility</NavDropdown.Item>
                            </NavDropdown>
                        </Nav> */}

                        <h1>Counter - Data Protection Notice</h1>
                        <h5>What is the purpose of this notice?</h5>
                        <p>This data protection notice (”Notice”) sets out details of the information that Counter App Limited (“Counter”, “we”, “us”, “our”) collected by us when you:</p>
                        <ol>
                            <li>Visit our websites, mobile sites and applications (“<strong>Sites</strong>”) that display or link to this Notice;</li>
                            <li>Visit our branded social media pages;</li>
                            <li>Visit our offices;</li>
                            <li>Receive communications from us, including emails, phone calls, texts or fax;</li>
                            <li>Use our products and services as an authorised user (for example, as an employee of one of our customers who provided you with access to our services) if and where we act as a controller of your personal data; or</li>
                            <li>Register for, attend and/or otherwise take part in our events or webinars.</li>
                        </ol>

                        <p>This Notice explains how we process your personal data, to whom we disclose it, and your rights under data protection law in relation to our processing of your personal data when you are based in the European Union (“EU”) or the European Economic Area (“EEA”), as required under the General Data Protection Regulation (EU 2016/679) (“GDPR”).</p>

                        <p>This Notice covers personal data that is collected through all of our Sites. Our Sites may contain links to other websites, applications and services maintained by third parties. The information practices of such other services, or of social media networks that host our branded social media pages, are governed by third parties’ privacy statements, which you should review to better understand those third parties’ privacy practices.</p>

                        <p>This Notice may be amended or updated from time to time to reflect changes in our practices with respect to the processing of your personal data, or changes in applicable law. We encourage you to read this Notice carefully, and to regularly check this page to review any changes we might make in accordance with the terms of this Notice.</p>

                        <h5>Who controls the use of your personal data?</h5>
                        <p>Counter is an Irish company that operates computer software that enables the following add-on software solutions to be integrated into the cloud-based property management software application system and platform developed and provided by Hostelworld.com Limited:</p>
                        <ol>
                            <li>Booking Engine – a property management software for accepting accommodation bookings; and</li>
                            <li>Payments System – a software service that enables Counter to accept and collect payments, manage bookings and perform transaction reporting or other financial transactions.</li>
                        </ol>

                        <p>Counter’s registered company number is 654485, and its registered address is {COUNTER_ADDRESS}. You can contact us using the details at the end of this Notice if you have any queries in relation to the processing of your personal data.</p>

                        <p>Counter is the controller of your personal data as described in this Notice where you provide your personal data to us through our Sites, unless expressly specified otherwise. For the avoidance of doubt, this Notice does not apply to the extent we process personal data in the role of a processor on behalf of our accommodation property customers, including where we offer to our customers various products and services through which our customers (and/or their affiliates): (i) sell or offer their own products and services; (ii) send electronic communications to other individuals; or (iii) otherwise collect, use, share or process personal data via our products and services. In these cases, our customer will be the responsible controller of the Personal Data concerned</p>

                        <p>For detailed privacy information related to where a Counter customer and/or a customer affiliate who uses our products and services is the controller, please reach out to the respective customer directly. We are not responsible for the privacy or data security practices of our customers, which may differ from those set forth in this Notice. If your data has been submitted to us by or on behalf a Counter customer and you wish to exercise any rights you may have under applicable data protection laws, please inquire with the applicable customer directly. Because we may only access a customer’s data upon instruction from that customer, if you wish to make your request directly to us, please provide to us the name of the Counter customer who submitted your data to us. We will refer your request to that customer and will support them as needed in responding to your request within a reasonable timeframe.</p>

                        <h5>What data do we collect?</h5>
                        <p>The data that we collect directly from you includes the following:</p>
                        <ol type="i">
                            <li>Personal information you give about yourself by filling in forms on our Sites or by corresponding with us by phone, e-mail address or otherwise. This includes information you provide when you register for an account. This information includes but is not limited to name, email address, postal address, billing address, telephone number, username and password.</li>
                            <li>Information you give us about your property(ies) that you advertise using our services, including the property address.</li>
                            <li>When we collect your email address, we will record your preferences regarding receipt of marketing emails from us in relation to our own products and services and those of third parties. </li>
                            <li>If you participate in a survey, we will record your responses to the survey. In some cases, we anonymise your responses, but where we don't do this, we will let you know.</li>
                            <li>When you visit our Sites, we may automatically collect technical information about your device and your usage of our Sites through cookies, web beacons or similar technologies, including your Internet Protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</li>
                            <li>Information about your visit to our Sites, including the full Uniform Resource Locators (URL) clickstream to, through and from our Sites (including date and time), page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</li>
                            <li>When we work with third parties to display advertisements, we may use technologies like cookies or pixel tags to record details of your interaction with those advertisements. This data is sometimes sent to us through cookies and pixel tags that are set on our behalf by those third parties.</li>
                            <li>Counter does not collect your payment card details. In order to pay for any fees related to our services, we use third party payment processors that will collect and process your payment card details.</li>
                            <li>Counter also works closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers) and we may receive information about you from them.</li>
                        </ol>

                        <p>If you provide us or our service providers with any personal data relating to other individuals, you represent that you have the authority to do so and acknowledge that it will be used in accordance with this Notice. If you believe that your personal data has been provided to us improperly, or to otherwise exercise your rights relating to your personal data, please contact us by using the information below.</p>


                        <h5>Why do we collect your data?</h5>
                        <p>The purposes for which your personal data are processed include:</p>
                        <ol type="i">
                            <li>Providing our Sites and services: We process your personal data to perform our contract with you for the use of our Sites and services and to fulfil our obligations under applicable terms of use/service; where we have not entered into a contract with you, we base the processing of your personal data on our legitimate interest to operate and administer our Sites and to provide you with content you access and request;</li>
                            <li>Promoting the security of our Sites and services: We process your personal data by tracking use of our Sites and services, creating aggregated, non-personal data, verifying accounts and activity, investigating suspicious activity and enforcing our terms and policies, to the extent this is necessary for our legitimate interest in promoting the safety and security of the services, systems and applications and in protecting our rights and the rights of others;</li>
                            <li>Providing necessary functionality: We process your personal data to perform our contract with you for the use of our sites and services; where we have not entered into a contract with you, we base the processing of your personal data on our legitimate interest to provide you with the necessary functionality required during your use of our Sites and services;</li>
                            <li>Managing you account registration: If you have registered for an account with us, we process your personal data by managing your user account for the purpose of performing our contract with you according to applicable terms of service;</li>
                            <li>Handling contact and your support requests: If you fill out a “Contact Us” web form or request user support, or if you contact us by other means including via a phone call, we process your personal data to perform our contract with you and to the extent it is necessary for our legitimate interest in fulfilling your requests and communicating with you;</li>
                            <li>Managing payments: If you have provided financial information to us, we process your personal data to verify that information and to collect payments to the extent that doing so is necessary to complete a transaction and perform our contract with you;</li>
                            <li>Developing and improving our Sites and services: We process your personal data to analyse trends and to track your usage of and interactions with our Sites and services to the extent it is necessary for our legitimate interest in developing and improving our Sites and services and providing you with more relevant content and service offerings, or where we seek your valid consent;</li>
                            <li>Assessing and improving user experience: We your process device and usage data as described above, which in some cases may be associated with your personal data, in order to analyse trends in order to assess and improve the overall user experience to the extent it is necessary for our legitimate interest in developing and improving the service offering, or where we seek your valid consent;</li>
                            <li>Reviewing compliance with applicable usage terms: We process your personal data to review compliance with the applicable usage terms in your contract to the extent that it is in our legitimate interest to ensure adherence to the relevant terms;</li>
                            <li>Assessing capacity requirements: We process your personal data to assess the capacity requirements of our services the extent that it is in our legitimate interest to ensure that we are meeting the necessary capacity requirements of our service offering;</li>
                            <li>Identifying new opportunities: We process your personal data to assess new potential opportunities with you to the extent that it is in our legitimate interest to ensure that we are meeting your demands as a customer and your users experience;</li>
                            <li>Displaying personalised advertisements and content: We process your personal data to conduct marketing research, advertise to you, provide personalised information about us on and off our Sites and to provide other personalised content based upon your activities and interests to the extent it is necessary for our legitimate interest in advertising our Sites or, where necessary, to the extent you have provided your prior consent;</li>
                            <li>Sending marketing communications: We process your personal data to send you marketing information, product recommendations and other non-transactional communications (e.g., marketing newsletters, telemarketing calls, SMS, or push notifications) about us and our affiliates and partners, including information about our products, promotions or events as necessary for our legitimate interest in conducting direct marketing or to the extent you have provided your prior consent.</li>
                            <li>Complying with legal obligations: We process your personal data when cooperating with public and government authorities, courts or regulators in accordance with our legal obligations under applicable laws to the extent this requires the processing or disclosure of personal data to protect our rights or is necessary for our legitimate interest in protecting against misuse or abuse of our Sites, protecting personal property or safety, pursuing remedies available to us and limiting our damages, complying with judicial proceedings, court orders or legal processes or to respond to lawful requests.</li>
                        </ol>

                        <h5>Who do we share your personal data with?</h5>
                        <p>Your personal data may be disclosed to third parties which are companies that provide services to Counter. These include:</p>

                        <ol type="i">
                            <li>Our contracted service providers who assist us in providing our services, some of whom will have access to your data. Where we engage a third party to process your data on our behalf, we make sure that they respect your privacy rights and that they process data in accordance with data protection law. Our third party service providers include:</li>
                            <ul>
                                <li><strong>Payment Processors</strong> - We use third-party payment processors in order to assist us with taking payments on our Sites.</li>
                                <li><strong>Customer Relationship Management</strong> - We use a third-party provider to host our customer relationship management system, which our Customer Services team uses to record all contacts that you have with us and how we have resolved your queries and complaints.</li>
                                <li><strong>Technology Providers</strong> - This includes service providers who provide services that help us to secure our network, systems and emails. It also includes providers of services that enable us to undertake particular tasks, such as surveys or market research, or to implement our advertising programme. Additionally, we may share anonymous usage data on an aggregate basis in the normal course of operating our business; for example, we may share information publicly to show trends about the general use of our services.</li>
                                <li><strong>Consultants</strong> - We sometimes engage consultants to help us implement new technology or services, or to provide us with insights into how we run our business and how we might improve it. This sometimes requires us to provide access to our systems to those third parties, which means that they may have access to where your personal data is stored. We always make sure that any such access is limited to what is strictly necessary, and subject to appropriate security procedures.</li>
                                <li><strong>Third-Party Advertisers</strong> - When we purchase advertising on third-party websites, we may share information with them about your use of our services, which they then combine with the information that they hold about you in order to implement the particular advertising campaign. The data that is shared with third-party advertisers relates to how you use our websites and apps, such as the pages you have visited, the searches you have made, and whether you have made a booking. We use specialist service providers to help us purchase advertising, and to transfer the information about your use of our services to third party advertisers that is required for those third party advertisers to be able to show you the correct advert.</li>
                                <li><strong>Social Media</strong> – We may share your personal data with third-party social media networks, advertising networks and websites, which usually act as separate controllers, so that Counter can market and advertise on third party platforms and websites.</li>
                                <li><strong>Corporate structuring</strong> - If we are involved in a merger, reorganisation, dissolution or other fundamental corporate change, or sell a Site or business unit, or if all or a portion of our business, assets or stock are acquired by third party, with such third party. In accordance with applicable laws, we will use reasonable efforts to notify you of any transfer of personal data to an unaffiliated third party.</li>
                            </ul>
                        </ol>

                        <h5>Transfers outside of the EU</h5>
                        <p>There are certain circumstances where we will transfer your personal data outside of the European Union to a country which is not recognised by the European Commission as providing an equivalent level of protection for personal data as is provided for in the European Union. The most common of these is where we transfer personal data to an accommodation provider so that they can fulfil your booking. We may also transfer your personal data outside of the European Union in connection with the operation of our business, such as when we use a service provider that is based in another jurisdiction. If we transfer your personal data outside of the European Union please rest assured that we will ensure that appropriate measures are in place to protect your personal data and to comply with our obligations under applicable data protection law. For example, when we transfer your personal data outside of the European Union, if required under data protection law we will either enter into contracts in the form approved by the European Commission with the entity that we transfer data to, or we will ensure that the company to which we transfer your personal data has agreed to abide by an approved transfer mechanism, such as the EU-US Privacy Shield framework. If you would like further details about the measures we have taken in relation to the transfer of your personal data, or copies of the agreements that we have put in place in relation to the transfers, please contact us using the details at the bottom of this Notice.</p>

                        <h5>Retention of personal data</h5>
                        <p>We may retain your personal data for a period of time consistent with the original purpose of collection. We determine the appropriate retention period for personal data on the basis of the amount, nature and sensitivity of your personal data processed, the potential risk of harm from unauthorised use or disclosure of your personal data whether we can achieve the purposes of the processing through other means, as well as on the basis of applicable legal requirements (such as applicable statutes of limitation).</p>
                        <p>After expiry of the applicable retention periods, your personal data will be securely deleted. If there is any data that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further use of such data.</p>

                        <h5>How we secure your personal data</h5>
                        <p>We take precautions including organisational, technical and physical measures to help safeguard against the accidental or unlawful destruction, loss, alteration and unauthorised disclosure of, or access to, the personal data we process or use. While we follow generally accepted standards to protect personal data, no method of storage or transmission is 100% secure. You are solely responsible for protecting your password, limiting access to your devices and signing out of websites after your sessions.</p>

                        <h5>Cookies, web beacons and other tracking technologies on our Sites and in email communications </h5>
                        <p>We use cookies and similar technologies such as web beacons, tags and JavaScript, alone or in conjunction with cookies, to compile information about the usage of our Sites and interaction with emails from us.</p>
                        <p>When you visit our Sites, we or an authorised third party may place a cookie on your browser and/or device, which collects information, including personal data, about your online activities over time and across different sites. Cookies allow us to track usage, determine your browsing preferences and improve and customise your browsing experience.</p>
                        <p>We use both session-based and persistent cookies on our websites. Session-based cookies exist only during one session and disappear from your computer when you close your browser or turn off your computer. Persistent cookies remain on your computer or device after you close your browser or turn off your computer. To change your cookie settings and preferences for the site you are visiting, click the Cookies link in the footer of the page. You can also control the use of cookies at the individual browser level, but choosing to disable cookies may limit your use of certain features or functions on our websites and services.</p>
                        <p>We also use web beacons on our websites and in email communications. For example, we may place web beacons in marketing emails that notify us when you click on a link in the email that directs you to one of our Sites. Such technologies are used to operate and improve our websites and email communications. </p>
                        <p>The following describes how we use different categories of cookies and similar technologies and your options for managing the data collection settings of these technologies:</p>


                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th className="w-25">Type of Cookies</th>
                                    <th className="w-50">Description</th>
                                    <th className="w-25">Managing Settings</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Required cookies</td>
                                    <td>
                                        <p>Required cookies are necessary for basic website functionality. Some examples include: session cookies needed to transmit the website, authentication cookies, and security cookies.</p>
                                        <p>If you have chosen to identify yourself to us, we may place on your browser a cookie that allows us to uniquely identify you when you are logged into the websites and to process your online transactions and requests.</p>
                                    </td>
                                    <td>Because required cookies are essential to operate the websites, there is no option to opt out of these cookies.</td>
                                </tr>
                                <tr>
                                    <td>Functional cookies</td>
                                    <td>
                                        <p>Functional cookies enhance functions, performance, and services on the website. Some examples include: cookies used to analyse site traffic, cookies used for market research, and cookies used to display advertising that is not directed to a particular individual.</p>
                                        <p>Functional cookies may also be used to improve how our websites function and to help us provide you with more relevant communications, including marketing communications. These cookies collect information about how our websites are used, including which pages are viewed most often.</p>
                                        <p>We may use our own technology or third-party technology to track and analyse usage information to provide enhanced interactions and more relevant communications, and to track the performance of our advertisements.</p>
                                        <p>For example, we use Google Analytics ("Google Analytics"), a web analytics service provided by Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. You can learn about Google’s privacy practices by going to www.google.com/policies/privacy/partners/.</p>
                                        <p>Google Analytics uses cookies to help us analyse how our websites are used, including the number of visitors, the websites from which visitors have navigated to our websites, and the pages on our websites to which visitors navigate. This information is used by us to improve our websites.</p>
                                    </td>
                                    <td>
                                        <p>You can choose to opt out of functional cookies. To change your cookie settings and preferences, click the <em>Cookie</em> link in the footer of the page.</p>
                                        <p>To opt out from data collection by Google Analytics, you can download and install a browser add-on, which is available <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank"  rel="noopener noreferrer">here</a>.</p>
                                        <p>To learn how to control functional cookies via your individual browser settings, click <a href="https://www.aboutcookies.org/" target="_blank"  rel="noopener noreferrer">here</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Targeting or Advertising cookies</td>
                                    <td>
                                        <p>Targeting or advertising cookies track activity across websites in order to understand a viewer’s interests, and to direct specific marketing to them. Some examples include: cookies used for remarketing, or interest-based advertising.</p>
                                        <p>We sometimes use cookies delivered by us or by third parties to show you ads for our products that we think may interest you on devices you use and to track the performance of our advertisements. For example, these cookies collect and remember information such as which browsers have visited our websites.</p>
                                        <p>Counter also contracts with third-party advertising networks that collect IP addresses and other information from web beacons on our websites, from emails and on third-party websites. Advertising networks follow your online activities over time and across different websites or other online services by collecting device and usage data through automated means, including through the use of cookies. These technologies may recognise you across the different devices you use, such as a desktop or laptop computer, smartphone or tablet. Third parties use this information to provide advertisements about products and services tailored to your interests. You may see their advertisements on other websites or mobile applications on any of your devices. This process also helps us manage and track the effectiveness of our marketing efforts.</p>
                                    </td>
                                    <td>You can choose to opt out of targeting and advertising cookies. To change your cookie settings and preferences, click the <em>Cookie</em> link in the footer of the page.</td>
                                </tr>
                            </tbody>
                        </Table>

                        <p>As described above, we or third parties may place or recognise a unique cookie on your browser when you visit our Sites for the purpose of serving you targeted advertising (also referred to as “online behavioural advertising” or “interest-based advertising”). To learn more about targeted advertising and advertising networks, please visit the opt-out pages of the Network Advertising Initiative, here, and the Digital Advertising Alliance, <a href="https://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">here</a>. To manage the use of targeting and advertising cookies on this website, click the Cookie link in the footer of the page or consult your individual browser settings for cookies. </p>

                        <p>In addition to utilising the user preference centre, where available, you may opt-out from the collection of non-essential device and usage data on your web browser by managing your cookies at the individual browser level. In addition, if you wish to opt-out of interest-based advertising click <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">here</a>.  Please note, however, that by blocking or deleting cookies and similar technologies used on our websites, you may not be able to take full advantage of the websites.</p>
                        <p>While some internet browsers offer a “do not track” or “DNT” option that lets you tell websites that you do not want to have your online activities tracked, these features are not yet uniform and there is no common standard that has been adopted by industry groups, technology companies or regulators. Therefore, we do not currently commit to responding to browsers' DNT signals with respect to our websites. Counter takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.</p>

                        <h5>Important Information about Consent</h5>
                        <p>When we process your personal data on the basis of your consent, you are free to withdraw that consent at any time. You can withdraw your consent by contacting us using the contact details at the bottom of this notice. If your consent relates to receiving email marketing you can use the unsubscribe link in the email, or if it relates to app notifications you can turn off app notifications using your device settings. Please note that if you withdraw your consent, we may not be able to continue to provide the related service to you.</p>

                        <h5>Your rights under data protection law</h5>
                        <p>You have various rights under data protection law in connection with our processing of your personal data. For example, you have the right to request a copy of your personal data that we hold and to request that we correct any errors in the personal data that we hold. These rights are subject to certain exceptions and exemptions.</p>
                        <p>You have the following rights under data protection law:</p>
                        <ul>
                            <li><strong>Right to access your personal data</strong> - You have the right to request a copy of the personal data that we hold about you, together with other information about our processing of that personal data.</li>
                            <li><strong>Right to rectification</strong> – You have the right to request that any inaccurate data that is held about you is corrected, or if we have incomplete information you may request that we update the information so that it is complete.</li>
                            <li><strong>Right to erasure</strong> – You have the right to request us to delete personal data that we hold about you. This is sometimes referred to as the right to be forgotten.</li>
                            <li><strong>Right to restriction of processing or to object to processing</strong> – You have the right to request that we no longer process your personal data for particular purposes, or to object to our processing of your personal data for particular purposes.</li>
                            <li><strong>Right to data portability</strong> – You have the right to request us to provide you, or a third party, with a copy of your personal data in a structured, commonly used machine readable format.</li>
                        </ul>

                        <h5>Questions and Complaints</h5>
                        <p>If you have any queries or complaints in connection with our processing of your personal data, you can get in touch with us using the following contact details:<br />
                        Email: <a href="mailto:support@counter.app">support@counter.app</a><br />
                        Phone: <a href="tel:+35314980720">+35314980720</a><br />
                        Post: Counter App Limited, {COUNTER_ADDRESS}</p>
                        <p>You also have the right to lodge a complaint with the Irish Data Protection Commission, or your local data protection supervisory authority, if you are unhappy with our processing of your personal data. Details of how to lodge a complaint can be found on the <a href="http://www.dataprotection.ie/" target="_blank" rel="noopener noreferrer">www.dataprotection.ie</a> website, or you can call the Data Protection Commission on <a href="tel:+3531890252231">+353 1 890 252 231</a>.</p>
                    </Col>
                </Row>
            </Col>
            {/* <Col lg={3} md={2} className="width m-0 p-0 d-md-none d-lg-block">
                <Nav className="d-flex flex-row align-items-center justify-content-center sticky-top h-100">
                    <div className="sidebar-nav">
                        <Nav.Item><Nav.Link href="/privacy-policy" className="active">Privacy policy</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/terms">Terms & conditions</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/accessibility">Accessibility</Nav.Link></Nav.Item>
                    </div>
                </Nav>
            </Col> */}
        </Row>
    </Container>
);