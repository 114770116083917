import React from 'react';
import { Container, Row, Col,  Image, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const Styled = styled.div`
    
.community-header {
    background: #c3ac78;
    text-align: center;
    -webkit-box-shadow: 0px 5px 7px -3px rgba(107,107,107,1);
    -moz-box-shadow: 0px 5px 7px -3px rgba(107,107,107,1);
    box-shadow: 0px 5px 7px -3px rgba(107,107,107,1);
    border-bottom: 0;
    padding: 20px 0;
  }
  
  .community-header h1 {
    width: 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin:0
  }
  
  .community-body h2 {
    margin: 20px 0;
    font-size: 35px;
  }
  .community-body p {
    font-weight: 700;
    color: #605B5B;
    margin-bottom: 0;
    font-size: 16px;
  }
  .community-body img {
    max-width: 250px;
  }
  .community-body .btn-primary {
    background-color: #fff;
    border: 2px solid #000;
    color: #000;
    padding: 10px;
    min-width:150px;
    margin-top: 20px;
    margin-right: 20px;
    
    &:hover,
    &:focus,
    &:active {
      background-color: #000;
      color: #fff;  
      outline:0;
    }

    @media (max-width: 425px) { 
      margin-right: 0;
    }
  }

  .community-body .btn-secondary {
    background-color: #000;
    border: 2px solid #000;
    padding: 10px;
    color:#fff;
    min-width:150px;
    margin-top: 20px;

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      color: #000;
      outline:0;
    }
  }
`;

const Community = (props) => {
    const { t } = props;

    return(
        <React.Fragment>
            <Styled>
            <section className="community-header mb-3">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1>{t('community.title')}</h1>
                    </Col>
                </Row>
            </Container>
            </section>
            <Container className="community-body my5 text-center">
                <Row>
                    <Col md={{span:6, offset:3}}>
                        <Image src={process.env.PUBLIC_URL + '/assets/images/community.png'} fluid />
                    </Col>
                </Row>
              <Row className="mb-5">
                  <Col md={{span:6, offset:3}}>
                        <h2 className="heading">{t('community.heading')}</h2>
                        <p>{t('community.content1')}</p>
                        <p>{t('community.content2')}</p>
                        <p>{t('community.content3')}</p>
                        <p>{t('community.content4')}</p>
                        <Button href="https://workspace.counter.app/login" className='btn-outline' target="_blank">{t('community.btn1')}</Button>
                        <Button href="https://workspace.counter.app/signup" className='btn-black' target="_blank" variant='secondary'>{t('community.btn2')}</Button>
                  </Col>
              </Row>
            </Container>
            </Styled>
        </React.Fragment>
        )
    }

export default withTranslation()(Community);