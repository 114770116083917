import React from 'react';
import { Container } from 'react-bootstrap';
import { PricingCards } from '../components/pricing/PricingCards';

export const Pricing = () => (
    <React.Fragment>
        <Container style={{background: '#F0F0F0', maxWidth: '100vw'}}>

            <PricingCards />

        </Container>
    </React.Fragment>
);