import React from 'react';
import { Container, Nav, NavDropdown, Row, Col } from 'react-bootstrap';


export const Accessibility = () => (
    <Container>
        <Row>
            <Col role="main">
                <Row>
                    <Col xs={12}>
                        <Nav>
                            <NavDropdown title="Accessibility" className="sidebar-responsive d-lg-none d-md-block">
                                <NavDropdown.Item  href="/privacy-policy">Privacy policy</NavDropdown.Item>
                                <NavDropdown.Item href="/terms">Terms & conditions</NavDropdown.Item>
                                <NavDropdown.Item href="/accessibility">Accessibility</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                        <h1>Accessibility</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in lectus in nibh facilisis luctus. Maecenas sagittis pellentesque sapien, vitae dignissim nisl luctus interdum. Aenean risus justo, vestibulum ultrices posuere ornare, consectetur ac enim. Pellentesque egestas eleifend diam in tincidunt. Cras eget dignissim lacus. Praesent condimentum arcu nisi, ut ultrices lorem imperdiet sed. Suspendisse a elit quis erat volutpat bibendum. Proin metus odio, hendrerit at fermentum quis, suscipit id felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla nec mattis nibh, in dignissim turpis.</p>

                        <p>Integer sem nulla, malesuada non gravida sit amet, porttitor ac lorem. Nam gravida facilisis pulvinar. Maecenas lacinia tellus in diam pretium gravida. Pellentesque massa tellus, egestas sed odio vitae, convallis mollis massa. Duis at scelerisque nisi. Phasellus eu dapibus orci. Mauris tortor urna, vestibulum eget consectetur id, fringilla non sapien. Vivamus sagittis facilisis auctor. Pellentesque mollis posuere tincidunt. Fusce at mauris vel ante ullamcorper sollicitudin.</p>

                        <p>Phasellus nibh ex, fermentum id tristique vel, consequat a lectus. Donec consequat nec nulla et fringilla. Aenean in ligula in eros mollis auctor non facilisis leo. Fusce lectus lacus, convallis vel tempor et, mattis non urna. Suspendisse velit libero, dapibus ut augue et, semper tincidunt purus. Donec gravida felis non consequat sollicitudin. Ut imperdiet ante quis est rutrum bibendum at non eros. Pellentesque luctus, ipsum nec lacinia vestibulum, nulla lorem volutpat risus, sit amet dignissim nisl ipsum varius neque. In volutpat, quam quis aliquet luctus, nulla nibh hendrerit ex, et eleifend dolor odio et mi. Duis tempus sem vitae lacus imperdiet semper. Curabitur et consequat elit. Cras suscipit rhoncus elit, non volutpat ex vulputate pharetra. Donec nec elit sit amet tortor egestas maximus. Suspendisse risus neque, accumsan eu porttitor et, tincidunt quis nulla. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam massa est, varius malesuada rhoncus sodales, bibendum at leo.</p>
                    </Col>
                </Row>
            </Col>
            <Col lg={3} md={2} className="width m-0 p-0 d-md-none d-lg-block">
                <Nav className="d-flex flex-row align-items-center justify-content-center sticky-top h-100">
                    <div className="sidebar-nav">
                        <Nav.Item><Nav.Link href="/privacy-policy">Privacy policy</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/terms">Terms & conditions</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/accessibility" className="active">Accessibility</Nav.Link></Nav.Item>
                    </div>
                </Nav>
            </Col>
        </Row>
    </Container>
);