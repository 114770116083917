import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const scriptURL = 'https://script.google.com/a/counter.app/macros/s/AKfycbyWdmqWSL4pFRzNfL2dGUBDuVMrbMkLfJen_UoZYQ/exec';

const useStyles = makeStyles(theme=>({
    toastMarginBottom:{
        marginBottom: '0px', 
    },
    toastIcon:{
        float: 'left',
        marginRight: '10px'
    }
}))
const NewsLetterSignup = (props) => {
    const classes = useStyles();
    const [ isloading, setIsLoading ] = useState(false);
    const [toastData, setToastData] = useState({ show: false, body: '' });

    const { t } = props;

    const getToastBody = (type,message) =>{
        return(
            <div>
                {type === 'error' ? <ErrorIcon className={classes.toastIcon} /> : <CheckCircleIcon className={classes.toastIcon}/>}
                <p className={classes.toastMarginBottom}>{message}</p>
            </div>
        )
    }

    const submitHander = event =>{
        setIsLoading(true);
        event.preventDefault();
        const email = event.target['email'].value;
        const isUpdateRequired = event.target['isUpdateRequired'].checked;
        event.target.reset();
        const url = `${scriptURL}?email=${email}&isUpdateRequired=${isUpdateRequired}`;
        fetch(url)
        .then(async(response) => {
            let data = await response.json();
            console.log('Success!', data);
            setIsLoading(false);
            if(data.success){
                setToastData({ show: true, body: getToastBody('success','Your request has been submitted successfully.') });
                return;
            }

            if(!data.is_email_valid){
                setToastData({ show: true, body: getToastBody('error','Email entered is invalid.') });
                return;
            }

            if(data.is_existing_email){
                setToastData({ show: true, body: getToastBody('error','Your request has already been submitted.')});
                return;
            }
        })
        .catch(error => {
            console.error('Error!', error.message);
            setToastData({ show: true, body: 'Please try again.' });
            setIsLoading(false);
        });
    }

    return(
        <Form className="signup-form" onSubmit={submitHander}>
                <Form.Group controlId="updatesCheckbox">
                    <Form.Check 
                        type="checkbox" 
                        name="isUpdateRequired"
                        label={t('footer.nav4Content')}
                        custom
                    />
                </Form.Group>
                <Form.Group controlId="newsLetterEmail">
                    <InputGroup>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={t('footer.emailPlaceholder')}
                            aria-describedby="newsletterEmail"
                            required
                        />
                        <InputGroup.Append>
                            <Button 
                                type="submit" 
                                className="btn-signup"
                                disabled={isloading}
                            >
                                {isloading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="sr-only">Loading...</span>
                                    </> 
                                    ) : (
                                        <ArrowForwardIcon />
                                )}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                {toastData.show && (
                    <Toast
                        style={{
                            position: 'absolute',
                            zIndex: 999
                        }}
                        onClose={() => setToastData({show: false, body: ''})} 
                        show={toastData.show} 
                        delay={5000}
                        autohide
                    >
                        <Toast.Body>{toastData.body}</Toast.Body>
                    </Toast>
                )}
            </Form>
    );
}

export default withTranslation()(NewsLetterSignup);