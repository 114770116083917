import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import Select, { components } from "react-select";

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import styled from 'styled-components';
import CommunityModal from './CommunityModal';
import NewsLetterSignup from './NewsLetterSignup';

const Styled = styled.div`
    footer {
        border-top:2px solid #000;
    }
    .btn-signup {
        background-color: #000;
        border-color: #000;
        padding: 5px 10px;
        min-width: 46px;

        &:hover, 
        &:focus,
        &:active {
            background-color: #000;
            border-color: #000;
        }
    }

    a {
        color:#000;

        &:hover {
            color: #000;
            text-decoration:none;
        }
    }

    .list-unstyled li {
        margin-bottom: 10px;
    }

    .list-inline li {
        display: inline-block;
        margin-right: 12px;
        font-size: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
    
    .footer-terms .list-inline li {
        margin: 0 1.66em;
        
        &:last-child {
            margin-right: 0;
        }
    }

    
    .signup-form {
        .form-control {
            height: calc(1.5em + .75rem + 4px);
        }
        .form-group label {
            font-size: 13px;
            line-height: 24px;
        }
    }

    .select-country {
        max-width: 200px;
        padding: 10px;
        box-shadow: none;
        border: 0px solid #dee2e6;
    }
  
`;

const Footer = props => {
    const [modalShow, setModalShow] = useState(false);

    const { t } = props;

    const options = [
        { value: "en", label: "English", icon: 'gb' },
        { value: "es", label: "Spanish", icon: 'es' }
    ];
    
    // custom select options
    const Option = (props) => (
        <components.Option {...props} className='option-list'>
            {props.data.label}
        </components.Option>
    );
    //custom select value
    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            {children}
        </components.SingleValue>
    );

    const handleChange = event => {
        console.log("selected val is ", event);
        console.log("state value is", event.value);
        props.i18n.changeLanguage(event.value);
    };

    return(
        <Styled>
        <footer className="pt-md-5">
            <div className="py-3">
                <Container>
                    <Row>
                        <Col lg={7} xs={12}>
                            <Row>
                                <Col lg={3} xs={12}>
                                    <h5>{t('footer.nav1Heading')}</h5>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="https://workspace.counter.app/signup" target="_blank" rel="noopener noreferrer">{t('footer.nav1Link1')}</a>
                                        </li>
                                        <li>
                                            <a href="/pricing">{t('footer.nav1Link3')}</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={3} xs={12}>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="https://medium.com/counter-app" target="_blank" rel="noopener noreferrer">{t('footer.nav2Link2')}</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={5} xs={12}>
                                    <h5>{t('footer.nav3Heading')}</h5>
                                    <ul className="list-unstyled">
                                        {/* <li>
                                            <a href="/faq">FAQ</a>
                                        </li> */}
                                        <li><a href="mailto:support@counter.app">support@counter.app</a></li>
                                        <li>
                                            <ul className="list-inline">
                                                <li>
                                                    <a href="https://www.instagram.com/counter.app/" target="_blank" rel="noopener noreferrer">
                                                        <InstagramIcon />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/channel/UCuGfWoA5VYG-ZrAklpT5NnA" target="_blank" rel="noopener noreferrer">
                                                        <PlayCircleFilledIcon />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/Counterapp-111334180509726" target="_blank" rel="noopener noreferrer">
                                                        <FacebookIcon />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/counter-app" target="_blank" rel="noopener noreferrer">
                                                        <LinkedInIcon />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{span:4, offset:1}}  xs={12}>
                            <h4>{t('footer.nav4Heading')}</h4>
                            <NewsLetterSignup />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-3 footer-terms">
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                            <Select
                                className='select-translate'
                                defaultValue={options.find(e => e.value === props.i18n.language) || options[0]}
                                options={options}
                                menuPlacement='top'
                                isSearchable={false}
                                components={{ Option, SingleValue }}
                                onChange={(selectedLang) => 
                                    handleChange(selectedLang)
                                }
                                styles={{
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                    })
                                }}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <ul className="list-inline list-unstyled float-right">
                                <li>
                                    <a href="/privacy-policy">{t('footer.privacy')}</a>
                                </li>
                                <li>
                                    &copy; Counter App Limited, 2020
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>

        <CommunityModal  
            show={modalShow}
            onHide={() => setModalShow(false)}
        />

        </Styled>
    )
}

export default withTranslation()(Footer);