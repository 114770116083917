import React from 'react';
import Parser from 'html-react-parser';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 80,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 120,
  },
  container: {
    maxWidth: '1200px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  headerContainer: {
    width: '100%',
  },
  title: {
    fontSize: '40px',
    maxWidth: 400
  },
  navBar: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: -20
  },
  pillContainer: {
    background: '#FFFFFF',
    borderRadius: 50,
    display: 'flex',
    padding: 8,
  },
  pill: {
    color: '#808080',
    cursor: 'pointer',
    fontSize: 20,
    padding: '8px 20px',
  },
  selected: {
    borderRadius: 30,
    color: 'white',
    background: '#000000'
  },
  cardContainer: {
    background: '#FFFFFF',
    borderRadius: 20,
    boxShadow: '0 10px 2px 0 #F0F0F0',
    padding: 30,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    }
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    }
  },
  plan: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60%',
      marginRight: 20,
    }
  },
  amount: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 60,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    }
  },
  header: {
    fontSize: '40px',
    marginBottom: 10,
  },
  description: {
    color: '#454545',
    fontSize: '16px',
    marginBottom: 40,
    minHeight: 70,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      width: '80%',
    }
  },
  startingFrom: {
    color: '#454545',
    fontSize: '14px',
    minHeight: 20,
  },
  price: {
    fontSize: '80px',
    lineHeight: '120%',
  },
  month: {
    color: '#454545',
    fontSize: '14px',
    minHeight: 30,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 5,
      minHeight: 0,
    }
  },
  link: {
    cursor: 'pointer',
    fontSize: '12px',
  },
  button: {
    border: '2px solid #BDBDBD',
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: 40,
    padding: 15,
    textAlign: 'center',
    width: '100%',
    transition: 'all 0.15s ease-in-out 0s',
    transform: 'perspective(1000px) translateZ(0px)',
  },
  buttonFree: {
    color: '#FF894F',
    borderColor: '#FF894F',
    '&:hover': {
      color: 'white',
      background: '#FF894F',
      transform: 'perspective(1000px) translateZ(40px)',
    },
  },
  buttonBasic: {
    color: '#7D00FE',
    borderColor: '#7D00FE',
    '&:hover': {
      color: 'white',
      background: '#7D00FE',
      transform: 'perspective(1000px) translateZ(40px)',
    },
  },
  buttonPlus: {
    color: '#1CAB58',
    borderColor: '#1CAB58',
    '&:hover': {
      color: 'white',
      background: '#1CAB58',
      transform: 'perspective(1000px) translateZ(40px)',
    },
  },
  featureList: {
    marginTop: 20,
    marginBottom: 30
  },
  feature: {
    marginBottom: 20,
  },
  featureName: {
    borderBottom: '1px dashed rgb(221, 221, 221)',
    color: '#111111',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    cursor: 'help',
  },
  tooltip: {
    padding: 10,
  },
  featureDescription: {
    color: 'white',
    fontSize: '14px',
    lineHeight: 1.4,
  },
  subText: {
    marginTop: 10,
  },
  free: {
    '& span': {
      borderBottom: '2px solid #FF894F',
      color: '#FF894F',
      fontWeight: 600,
    }
  },
  basic: {
    '& span': {
      borderBottom: '2px solid #7D00FE',
      color: '#7D00FE',
      fontWeight: 600,
    }
  },
  plus: {
    '& span': {
      borderBottom: '2px solid #1CAB58',
      color: '#1CAB58',
      fontWeight: 600,
    }
  },
  bold: {
    color: 'red'
  }
}))

const pricing = [
  { 
    id: 2, 
    name: 'Plus', 
    description: 'Put your business to work. Automate payments and collect direct bookings.',
    yearlyPrice: '€44',
    monthlyPrice: '€49',
    color: '#7D00FE',
    subText: ''
  },
  {
    id: 3,
    name: 'Pro',
    description: 'Sell more. Turbo charge your online distribution and grow your team.',
    yearlyPrice: '€89',
    monthlyPrice: '€99',
    color: '#1CAB58',
    subText: 'Everything in PLUS and'
  },
]

const features = {
  2: [
        {
            id: '2-1',
            name: 'Unlimited beds and reservations',
            description: 'Manage all your rooms in a simple, easy-to-use calendar which allows you to drag and drop reservations'
        },
        {
            id: '2-2',
            name: 'All core PMS features',
            description: 'Edit reservations, log payment transactions, occupancy reports, create rate plans, set prices and so much more.. '
        },
        {
            id: '2-3',
            name: `Invite your team - <span>up to 5 users</span>`,
            description: 'Invite other people to collaborate with you on your Counter property'
        },
        {
            id: '2-4',
            name: `Auto-sync reservations - <span>up to 3 channels</span>`,
            description: 'Eliminate overbookings by connecting your property directly to multiple OTAs to automatically receive bookings, modifications, cancellations and to keep your inventory in sync',
            subText: 'We currently support Hostelworld, Booking.com, Airbnb and Expedia. Google, Agoda & Trip.com coming soon.'
        },
        {
            id: '2-5',
            name: 'Commission-free booking engine',
            description: 'Reduce your OTA commission expenses by channeling loyal customers to book directly on your own website'
        },
        {
            id: '2-6',
            name: 'Automated payment processing',
            description: 'Automatically charge Non-Refundable Rates, no-shows and cancellations. Receive a daily payment failure report via email.',
            subText: 'Reduce manual work with rule based charging. Eliminate costly human errors and increase your collection rate.'
        },
        {
            id: '2-7',
            name: 'Rule-based email templates',
            description: 'Configure email templates that can be triggered when guests make a reservation, check-out or at some other stage of their journey.',
            subText: 'Reduce manual work and ensure a consistent guest experience.'
        },
        {
            id: '2-8',
            name: 'Cash Audit',
            description: 'Cash count at the beginning of shift and end of shift allows the manager to be alerted if there have been any transaction errors during the shift.',
            subText: 'This is a powerful tool to prevent fraud.'
        },
        {
            id: '2-9',
            name: 'Online check-in',
            description: 'Automatically send your guests an online check-in link which enables them to enter their personal information, upload their ID, accept terms & conditions and complete payment.',
            subText: 'Going 100% contactless allows your guests to feel safer during their stay.'
        },
    ],
  3: [
        {
          id: '3-1',
          name: `Invite your team - <span>up to 10 users</span>`,
          description: 'Invite other people to collaborate with you on your Counter property'
        },
        {
          id: '3-2',
          name: `Auto-sync reservations - <span>up to 5 channels</span>`,
          description: 'Eliminate overbookings by connecting directly to multiple OTAs to automatically receive bookings, modifications, cancellations and to keep your inventory in sync',
          subText: 'We currently support Hostelworld, Booking.com, Airbnb and Expedia. Google, Agoda & Trip.com coming soon.'
        },
        {
          id: '3-3',
          name: 'Connect a Channel Manager',
          description: 'To access a wider range of regional OTAs, connect to an external channel manager.',
          subText: 'Counter currently connects to Siteminder, myAllocator and wuBook.'
        },
        {
          id: '3-4',
          name: 'Dedicated Onboarding Support.',
          description: 'During configuration phase, we\'ll guide you to discover Counter\'s features.',
          subText: 'Get the most out of this PMS with our assistance.'
        },
        {
          id: '3-5',
          name: 'Personalized one-to-one Video call.',
          description: 'Book a call with one of our experts to explore the tools we offer.',
          subText: 'Have an advice tailored for your property and unique reality.'
        },
    ]
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#333333',
    color: 'white',
    maxWidth: 250,
    fontSize: '12px',
    opacity: '0.7',
    borderRadius: 10,
  },
}))(Tooltip);

export const PricingCards = () => {
  const classes = useStyles();
  const [yearly, setYearly] = React.useState(true);

  const toggleYear = () => {
    if (yearly) setYearly(false)
    else setYearly(true)
  }

  const signup = () => {
    window.location.replace(`https://workspace.counter.app/signup`)
  }

  return (
    <div className={classes.root}>

      <div className={classes.container}>

        <div className={classes.headerContainer}>
          <div className={classes.title}>All-in-one workspace for hostels</div>
          <div className={classes.description}>Pick a plan, cancel any time.</div>
        </div>

        <div className={classes.navBar}>
          <div className={classes.pillContainer}>
            <div className={clsx({[classes.pill]: true, [classes.selected]: yearly})} onClick={() => setYearly(true)}>Yearly</div>
            <div className={clsx({[classes.pill]: true, [classes.selected]: !yearly})} onClick={() => setYearly(false)}>Monthly</div>
          </div>
        </div>

        <Grid container spacing={4}>

          {pricing.map(item => 
            <Grid key={item.id} item sm={12} md={6}>
              <div className={classes.cardContainer}>
                <div className={classes.priceContainer}>
                  <div className={classes.plan}>
                    <div className={classes.header} style={{color: item.color}}>{item.name}</div>
                    <div className={classes.description}>{item.description}</div>
                  </div>
                  <div className={classes.amount}>
                    <div className={classes.startingFrom}>{item.id ===3 && 'Starting from...'}</div>
                    <div className={classes.price}>{yearly ? item.yearlyPrice : item.monthlyPrice}</div>
                    <div className={classes.month}>{item.id !==1 && 'per month'} {item.id !==1 && yearly ? 'billed yearly' : ''}</div>
                    {window.innerWidth<960 && item.id!==1 && 
                      <div 
                        className={classes.link} 
                        style={{color: item.color}}
                        onClick={toggleYear}
                      >
                        show {yearly ? 'monthly' : 'yearly'}
                      </div>
                    }
                  </div>
                </div>
                <div 
                  className={clsx({
                    [classes.button]: true, 
                    [classes.buttonFree]: item.id===1,
                    [classes.buttonBasic]: item.id===2,
                    [classes.buttonPlus]: item.id===3
                  })}
                  onClick={signup}
                >
                  Get Started
                </div>
                <div className={classes.startingFrom}>{item.subText}</div>

                <div className={classes.featureList}>
                  {features[item.id].map(feature => 
                    <div key={feature.id} className={classes.feature}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div className={classes.tooltip}>
                              <div className={classes.featureDescription}>{feature.description}</div>
                              {feature.subText && <div className={clsx(classes.featureDescription, classes.subText)}>{feature.subText}</div>}
                            </div>
                          </React.Fragment>
                        }
                        arrow
                        placement='top'
                      >
                        <span 
                          className={clsx({
                            [classes.featureName]: true, 
                            [classes.free]: item.id===1,
                            [classes.basic]: item.id===2,
                            [classes.plus]: item.id===3
                          })}
                        >
                          {Parser(feature.name)}
                        </span>
                      </HtmlTooltip>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          )}
        
        </Grid>
      </div>
    </div>
  )
}