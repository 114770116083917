import React from 'react';
import { Modal, Button, Container, Row, Col, Image } from 'react-bootstrap';


const CommunityModal = (props) => {
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="community-modal"
        centered
        dialogClassName="community-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="community-modal">
            Covid-19 community platform for hostel owners
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
                <Row>
                    <Col md={{span:4, offset:4}}>
                        <Image src={process.env.PUBLIC_URL + '/assets/images/community.png'} fluid />
                    </Col>
                </Row>
              <Row>
                  <Col md={12}>
                        <h1 className="heading">We are in this together</h1>
                        <p>Buy or sell a Hostel</p>
                        <p>Ask the community a question</p>
                        <p>Get personalsupport from experts</p>
                        <p>& much more</p>
                        <Button href="https://workspace.counter.app/signup" >Join now</Button>
                  </Col>
              </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
}

export default CommunityModal